import React, { useState, useEffect } from "react";

const Footer = () => {
  const [cName, setCName] = useState();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    var company_info = JSON.parse(localStorage.getItem("company_info"));

    if (company_info) {
      setCName(company_info.company_name);
    }
  };

  return (
    <div>
      <footer className="main-footer">
        <strong>
          Copyright &copy; 2024 <a href="#">Recharge Ways</a>
        </strong>
      </footer>
    </div>
  );
};

export default Footer;
